
/* eslint-disable */
import { defineComponent, onMounted, Ref, ref, watch } from 'vue';
import axios from 'axios';

export default defineComponent({
  components: {
  },
  setup() {
    //#region Data
    const currentIndex = ref(-1);
    const songIndex = ref(0);
    const imagesList = ref([]);
    const songsList = ref([]);
    const transitionStyleList = ref([ 'slideLeft', 'zoom', 'slideUp', 'zoomout' ]);
    const selectedTransition = ref('slideLeft');
    const audioEl: Ref<any> = ref(null);

    const evenImage: Ref<any> = ref(null);
    const oddImage: Ref<any> = ref(null);

    const showEven = ref(false);
    const slideTime = ref(5000);
    
    const audioInterval: Ref<any> = ref(null);
    const currentSongTime = ref(0);
    const currentSongDuration = ref(260);
    const isPlay = ref(false);
    const showControls = ref(false);
    //#endregion

    //#region Lifecycle
    onMounted(async () => {
      imagesList.value = (await axios.get('https://api.reeqzan.com/AdeebHannaWish/GetSlideshowImageList')).data;
      songsList.value = (await axios.get('https://api.reeqzan.com/AdeebHannaWish/GetSongList')).data;

      currentIndex.value++;
      evenImage.value.src = `https://api.reeqzan.com/AdeebHannaWish/GetSlideshowImage?file=${imagesList.value[currentIndex.value]}`;

      setTimeout(() => {
        isPlay.value = true;
      }, 5000);

      window.addEventListener('keypress', (e: KeyboardEvent) => {
        if (e.key == ' ') {
          isPlay.value = !isPlay.value;
        }
      });

      // window.addEventListener('touchend', () => showControls.value = !showControls.value);
      // window.addEventListener('mousedown', () => showControls.value = !showControls.value);

      // window.addEventListener('mousemove', () => showControls.value = true);
      // window.addEventListener('mouseout', () => showControls.value = false);

      audioEl.value.onplaying = () => {
        isPlay.value = true;
        audioInterval.value = setInterval(audioPlaying, 1000);
      }
      audioEl.value.onpause = () => {
        isPlay.value = false;
        clearInterval(audioInterval.value);
      }
      audioEl.value.onended = () => {
        nextClicked();
      }
    })
    //#endregion
    
    //#region Methods
    const testClicked = () => {
      const random = Math.floor(Math.random() * transitionStyleList.value.length);
      selectedTransition.value = transitionStyleList.value[random];

      const next = currentIndex.value + 1;
      if (next >= imagesList.value.length) {
        currentIndex.value = 0;
      } else {
        currentIndex.value++;
      }
    }
    const audioPlaying = () => {
      currentSongTime.value = audioEl.value.currentTime;
      currentSongDuration.value = audioEl.value.duration;
    }
    const barClicked = (e: PointerEvent) => {
      const perc = Math.floor(e.offsetX / (e.target as any).getBoundingClientRect().width * 100);
      const duration = audioEl.value.duration;
      audioEl.value.currentTime = duration * perc / 100;
    }
    const prevClicked = () => {
      let next = songIndex.value - 1;
      if (next < 0) {
        songIndex.value = 0;
      } else {
        songIndex.value--;
      }

      setTimeout(() => {
        audioEl.value.play();
      }, 1000);
    }
    const nextClicked = () => {
      let next = songIndex.value + 1;
      if (next >= songsList.value.length) {
        songIndex.value = 0;
      } else {
        songIndex.value++;
      }

      setTimeout(() => {
        audioEl.value.play();
      }, 1000);
    }

    const changeTransition = () => {
      const random = Math.floor(Math.random() * transitionStyleList.value.length);
      selectedTransition.value = transitionStyleList.value[random];
    }
    const evenImageLoaded = () => {
      // console.log('even loaded');
      changeTransition();
      setTimeout(() => {
        showEven.value = !showEven.value;
        
        setTimeout(() => {
          currentIndex.value++;
          if (currentIndex.value >= imagesList.value.length) {
            currentIndex.value = 0;
          }
          oddImage.value.src = `https://api.reeqzan.com/AdeebHannaWish/GetSlideshowImage?file=${imagesList.value[currentIndex.value]}`;
        }, slideTime.value);
      }, slideTime.value / 10);
    }
    const oddImageLoaded = () => {
      // console.log('odd loaded');
      changeTransition();
      setTimeout(() => {
        showEven.value = !showEven.value;
        
        setTimeout(() => {
          currentIndex.value++;
          if (currentIndex.value >= imagesList.value.length) {
            currentIndex.value = 0;
          }
          evenImage.value.src = `https://api.reeqzan.com/AdeebHannaWish/GetSlideshowImage?file=${imagesList.value[currentIndex.value]}`;
        }, slideTime.value);
      }, slideTime.value / 10);
    }
    //#endregion

    //#region Watcher
    watch(isPlay, (val) => {
      if (val) {
        audioEl.value.play();
      } else {
        audioEl.value.pause();
      }
    })
    //#endregion
    
    return {
      //#region Data
      currentIndex,
      songIndex,
      imagesList,
      songsList,
      selectedTransition,
      audioEl,
      currentSongTime,
      currentSongDuration,
      isPlay,
      showControls,

      showEven,
      evenImage,
      oddImage,
      //#endregion
      
      //#region Methods
      testClicked,
      barClicked,
      prevClicked,
      nextClicked,
      evenImageLoaded,
      oddImageLoaded,
      //#endregion
    }
  },
})

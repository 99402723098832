import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/Previous.png'
import _imports_1 from '@/assets/Next.png'


const _withScopeId = n => (_pushScopeId("data-v-180a4543"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-main" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "control" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "time" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_TransitionGroup, { name: _ctx.selectedTransition }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("img", {
          key: "0",
          onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.evenImageLoaded && _ctx.evenImageLoaded(...args))),
          ref: "evenImage",
          onMousedown: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showControls = !_ctx.showControls)),
          onTouchend: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showControls = !_ctx.showControls))
        }, null, 544), [
          [_vShow, _ctx.showEven]
        ]),
        _withDirectives(_createElementVNode("img", {
          key: "1",
          onLoad: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.oddImageLoaded && _ctx.oddImageLoaded(...args))),
          ref: "oddImage",
          onMousedown: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showControls = !_ctx.showControls)),
          onTouchend: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showControls = !_ctx.showControls))
        }, null, 544), [
          [_vShow, !_ctx.showEven]
        ])
      ]),
      _: 1
    }, 8, ["name"]),
    _createElementVNode("audio", {
      src: `https://api.reeqzan.com/AdeebHannaWish/GetSongs?file=${_ctx.songsList[_ctx.songIndex]}`,
      ref: "audioEl"
    }, null, 8, _hoisted_2),
    _createElementVNode("div", {
      class: "audio-controls",
      style: _normalizeStyle({ opacity: _ctx.showControls ? '1' : '0' })
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: _imports_0,
          alt: "",
          onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.prevClicked && _ctx.prevClicked(...args)))
        }),
        _createElementVNode("img", {
          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.isPlay = !_ctx.isPlay)),
          src: require(`@/assets/${_ctx.isPlay ? 'Pause.png' : 'Play.png'}`),
          alt: ""
        }, null, 8, _hoisted_4),
        _createElementVNode("img", {
          src: _imports_1,
          alt: "",
          onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.nextClicked && _ctx.nextClicked(...args)))
        })
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", {
          class: "bar",
          onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.barClicked && _ctx.barClicked(...args)))
        }, [
          _createElementVNode("div", {
            class: "progress",
            style: _normalizeStyle({ width: `${_ctx.currentSongTime / _ctx.currentSongDuration * 100}%` })
          }, null, 4)
        ])
      ])
    ], 4)
  ]))
}